import { FaInstagram, FaSnapchat, FaTiktok } from 'react-icons/fa'
import { AiFillMail } from "react-icons/ai";
import { FooterContainer, FooterWrap, FooterLinkWrapper, FooterLinkItems, FooterLinkContainer, FooterLinkTitle, FooterLink, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const instagram = "https://instagram.com/studiorecsa?igshid=YmMyMTA2M2Y=";
  const snapchat = "https://t.snapchat.com/QyM1aFvk";
  const tiktok = "https://www.tiktok.com/@studio_recsa";

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contactez-nous</FooterLinkTitle>
                <FooterLink to='/'>Contact</FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>

          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
                <FooterLink to={instagram}>Instagram</FooterLink>
                <FooterLink to={snapchat}>Snapchat</FooterLink>
                <FooterLink to={tiktok}>Tiktok</FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinkContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              studiorecsa
            </SocialLogo>
            <WebsiteRights>studiorecsa © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href={instagram} target='_blank' arial-label='Instagram'>
                <FaInstagram/>
              </SocialIconLink>
              <SocialIconLink href='/' target='_blank' arial-label='Mail'>
                <AiFillMail />
              </SocialIconLink>
              <SocialIconLink href='https://www.tiktok.com/@studio_recsa' target='_blank' arial-label='Tiktok'>
                <FaTiktok />
              </SocialIconLink>
              <SocialIconLink href={snapchat} target='_blank' arial-label='Snapchat'>
                <FaSnapchat />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
