import { HeroContainer, HeroContent, HeroP } from './HeroElements'
import studio from '../../images/studio.svg';
import mp4 from '../../videos/recsa.mp4';
import Video from './Video';

const Hero = () => {
  //const [hover, setHover] = useState(false);

  //const onHover = () => {
  //  setHover(!hover)
  //}

  return (
    <HeroContainer id='home'>
      <Video src={mp4} />
      <HeroContent className="animated animatedFadeInUp fadeInUp">
        <img src={studio} alt="logo" style={{ maxHeight: "330px" }} />
        <HeroP>Site en cours de construction</HeroP>
        {/**
         * 
        <HeroBtnWrapper>
          <Button to='/signin' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false' smooth="true" duration={500} spy="true" exact='true' offset={-80}>
            Réserver {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
         */}
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
