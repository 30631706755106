import { useEffect, useRef } from "react";
import "./main.css";

interface Props {
    src: string;
};

const style: React.CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden"
}

const Video: React.FC<Props> = ({ src }) => {
    const video = useRef<HTMLDivElement>(null);
    const html = `
<video autoplay loop muted playsinline class="player">
    <source src=${src} type="video/mp4"
</video>
`;

    useEffect(() => {
        if (!video.current) {
            return;
        }

        const player = video.current.children[0] as HTMLVideoElement;

        if (player) {
            // set the video attributes using javascript as per the
            // webkit Policy
            player.controls = false;
            player.playsInline = true;
            player.muted = true;
            player.setAttribute("muted", ""); // leave no stones unturned :)
            player.autoplay = true;
        }
    }, []);

    return (
        <div
            ref={video}
            style={style}
            dangerouslySetInnerHTML={{
                __html: html
            }}
        />
    );
}

export default Video;