import { Container, FormWrap, Icon, FormContent, Form } from './SigninElements'
import { IonText } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';

import '@ionic/react/css/core.css';


const SignIn = () => {
  const instagram = "https://instagram.com/studiorecsa?igshid=YmMyMTA2M2Y=";
  const mailto = "mailto:studiorecsa@gmail.com?subject='Réservation'";
  setupIonicReact();

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>studiorecsa</Icon>
          <FormContent>
            <Form action='#'>
              <h2>
                <IonText color="warning">
                  Contactez-nous directement sur <a href={instagram}><IonText style={{textDecoration: "underline"}} color="success">Instagram</IonText></a> ou bien par <a href={mailto}><IonText style={{textDecoration: "underline"}} color="secondary">mail.</IonText></a>
                </IonText>
              </h2>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default SignIn
